import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import Login from './components/login/LoginPage.jsx'
import Lookup from './components/lookup/LookupPage.jsx'
import Register from './components/register/RegisterPage.jsx'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './css/bootstrap/bootstrap.min.css'
import './css/bootstrap/bootstrap-grid.min.css'
import './css/bootstrap/bootstrap-reboot.min.css'
import './css/hope-ui/custom.min.css'
import './css/hope-ui/customizer.min.css'
import './css/hope-ui/dark.min.css'
import './css/hope-ui/hope-ui.min.css'
import './css/hope-ui/libs.min.css'
import './css/hope-ui/rtl.min.css'
import RequireAuth from './components/RequireAuth.jsx'

const sessionBaseURL = "https://api.tesseractdevtool.com/session"

// Function to toggle theme
const toggleTheme = () => {
  const currentTheme = document.documentElement.getAttribute('data-theme')
  const newTheme = currentTheme === 'dark' ? 'light' : 'dark'
  document.documentElement.setAttribute('data-theme', newTheme)
}

// Adding event listener for theme toggle
document.addEventListener('DOMContentLoaded', () => {
  const themeToggleButton = document.getElementById('theme-toggle')
  if (themeToggleButton) {
    themeToggleButton.addEventListener('click', toggleTheme)
  }
})

const isAuthenticated = async () => {
  const token = localStorage.getItem('token')

  if (!token) return false

  try {
    // Send the token to the backend for validation
    const response = await fetch(`${sessionBaseURL}?token=${token}`)

    // Backend returns a status to confirm validity
    if (response.status === 200) {
      return true
    }
  } catch (error) {
    console.error('Token validation failed:', error)
    return false
  }

  return false
}
// Routing paths
const router = createBrowserRouter([
  {
    path: '/', // default page on load
    element: <Login />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/lookup',
    element: <RequireAuth isAuthenticated={isAuthenticated} />, // Protect this route
    children: [
      {
        path: '/lookup',
        element: <Lookup /> // This is the protected component
      }
    ]
  },
  {
    path: '*', // default handler that redirects any nonexistent URL to login page
    element: <Login />
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
