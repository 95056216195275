import { Grid } from '@mui/material'
import Card from '../Card.jsx'
import RegisterForm from './RegisterForm.jsx'

function RegisterPage() {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ minHeight: '100vh' }}>
      <Grid item xs={10} sm={9} md={7} lg={6} xl={5}>
        <Card>
          <h5 className='card-title text-center mb-5 fw-light fs-5'>
            Get Started
          </h5>
          <RegisterForm /> {/* Render the RegisterForm component */}
          <hr className='my-4' />
          <div className='d-grid'>
            <div className='text-center'>
              <p>
                <a href='/login' className='link-primary'>
                  Already have an Account?
                </a>
              </p>
            </div>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
}

export default RegisterPage
