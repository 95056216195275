import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import validate from './RegisterValidation'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function RegisterForm() {
  const navigate = useNavigate()
  const baseURL = 'https://api.tesseractdevtool.com/register' // LOCALHOST URL

  const [formData, setFormData] = useState({
    username: '',
    firstname: '',
    lastname: '',
    confirm: ''
  })
  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validate(formData)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      setErrors({})

      try {
        const response = await fetch(`${baseURL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: formData.username,
            firstname: formData.firstname,
            lastname: formData.lastname
          })
        })
        const data = await response.json()

        if (response.ok) {
          // Registration successful
          withReactContent(Swal).fire({
            icon: 'success',
            title: data.message,
            html: `<p>Registration complete for ${data.user.firstname} ${data.user.lastname}</p>`
          })
          navigate('/login')
        } else {
          // Registration failed
          withReactContent(Swal).fire({
            icon: 'error',
            title: data.message
          })
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className='register-form'>
      {/* Input fields */}
      <div className='row mb-3'>
        {/* First name input */}
        <div className='col-md-6'>
          <div
            className={`form-floating ${errors.firstname ? 'has-danger' : ''}`}
            style={{ position: 'relative' }}>
            <input
              type='text'
              className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}
              id='firstName'
              placeholder='First Name'
              name='firstname'
              value={formData.firstname}
              onChange={handleInputChange}
              autoComplete='off'
              required
              style={{
                borderColor: errors.firstname ? '#dc3545' : '#ced4da', // Border color for errors
                borderWidth: '1px', // Border width
                borderStyle: 'solid' // Solid border style
              }}
            />
            <label htmlFor='firstName'>First Name</label>
            {errors.firstname && (
              <div className='invalid-feedback'>{errors.firstname}</div>
            )}
          </div>
        </div>
        {/* Last name input */}
        <div className='col-md-6'>
          <div
            className={`form-floating ${errors.lastname ? 'has-danger' : ''}`}
            style={{ position: 'relative' }}>
            <input
              type='text'
              className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
              id='lastName'
              placeholder='Last Name'
              name='lastname'
              value={formData.lastname}
              onChange={handleInputChange}
              autoComplete='off'
              required
              style={{
                borderColor: errors.lastname ? '#dc3545' : '#ced4da', // Border color for errors
                borderWidth: '1px', // Border width
                borderStyle: 'solid' // Solid border style
              }}
            />
            <label htmlFor='lastName'>Last Name</label>
            {errors.lastname && (
              <div className='invalid-feedback'>{errors.lastname}</div>
            )}
          </div>
        </div>
      </div>
      {/* Username input */}
      <div
        className={`form-floating mb-3 ${errors.username ? 'has-danger' : ''}`}
        style={{ position: 'relative' }}>
        <input
          type='text'
          className={`form-control ${errors.username ? 'is-invalid' : ''}`}
          id='username'
          placeholder='Username'
          name='username'
          value={formData.username}
          onChange={handleInputChange}
          autoComplete='off'
          required
          style={{
            borderColor: errors.username ? '#dc3545' : '#ced4da', // Border color for errors
            borderWidth: '1px', // Border width
            borderStyle: 'solid' // Solid border style
          }}
        />
        <label htmlFor='username'>Username</label>
        {errors.username && (
          <div className='invalid-feedback'>{errors.username}</div>
        )}
      </div>
      {/* Confirm username input */}
      <div
        className={`form-floating mb-3 ${errors.confirm ? 'has-danger' : ''}`}
        style={{ position: 'relative' }}>
        <input
          type='text'
          className={`form-control ${errors.confirm ? 'is-invalid' : ''}`}
          id='confirm'
          placeholder='Confirm Username'
          name='confirm'
          value={formData.confirm}
          onChange={handleInputChange}
          autoComplete='off'
          required
          style={{
            borderColor: errors.confirm ? '#dc3545' : '#ced4da', // Border color for errors
            borderWidth: '1px', // Border width
            borderStyle: 'solid' // Solid border style
          }}
        />
        <label htmlFor='confirm'>Confirm Username</label>
        {errors.confirm && (
          <div className='invalid-feedback'>{errors.confirm}</div>
        )}
      </div>
      {/* Submit button */}
      <div className='d-grid mb-3'>
        <button
          className='btn btn-primary btn-login font-weight-bold'
          type='submit'>
          Register
        </button>
      </div>
    </form>
  )
}

export default RegisterForm
