import React, { useState } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material'
import {
  PhotoCamera, 
  TableChart
} from '@mui/icons-material'
import Card from '../Card.jsx'
import AddMaterial from './AddMaterial.jsx'
import { useNavigate } from 'react-router-dom'
import DynamicInputField from './DynamicField.jsx'
import FileUpload from './FileUpload.jsx'
import AutoLookup from './AutoLookup.jsx'

function LookupPage() {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [showAddMaterial, setShowAddMaterial] = useState(true) // Toggle state
  const [toggleCamera, setToggleCamera] = useState(true) // Toggle state

  const handleLogoutClick = () => {
    navigate('/')
  }

  const handleManualSearch = (data) => {
    setTableData(data) // Replace existing data with new search results
  }

  const copyToClipboard = () => {
    const tableHeader = ['CAS Number', 'Material Name', 'H-Codes', 'Description'];
    const rows = tableData.map(row => [
      row.cas,
      row.materialName,
      row.hCodes.map(hCode => hCode.code).join('\n'), // Join H-Codes with a newline
      row.hCodes.map(hCode => hCode.description).join('\n') // Join Descriptions with a newline
    ]);

    // Create a string with rows separated by newlines and columns by tabs
    const csvContent = [tableHeader, ...rows].map(e => e.join("\t")).join("\n");

    navigator.clipboard.writeText(csvContent)
      .then(() => alert('Table copied to clipboard!'))
      .catch(err => console.error('Could not copy text: ', err));
  }

  return (
    <>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100px', padding: '16px' }}
        className='main-container'>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          justifyContent='center'
          style={{ maxWidth: '1288px' }}
          className='lookup-grid'>

          <Grid
            item
            xs={12}
            md={6}
            lg={8}
            className='manual-add-material-column'>
            <Card variant='lookup'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                  marginTop: '-20px'
                }}>
                <div>
                  <Button
                    variant='contained'
                    color={toggleCamera ? 'primary' : 'default'}
                    onClick={() => setToggleCamera(true)}
                    style={{ marginRight: '8px' }}>
                    <TableChart/>
                  </Button>
                  <Button
                    variant='contained'
                    color={!toggleCamera ? 'primary' : 'default'}
                    onClick={() => setToggleCamera(false)}>
                    <PhotoCamera/>
                  </Button>
                </div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={copyToClipboard}>
                  Copy All
                </Button>
              </div>
              {toggleCamera ? 
              <TableContainer
                component={Paper}
                style={{ minHeight: '625px', backgroundColor: 'white' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>CAS Number</TableCell>
                      <TableCell>Material Name</TableCell>
                      <TableCell>H-Codes</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                          No data available
                        </TableCell>
                      </TableRow>
                    ) : (
                      tableData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.cas}</TableCell>
                          <TableCell>{row.materialName}</TableCell>
                          <TableCell>
                            {row.hCodes.map((hCode, idx) => (
                              <h5 key={idx}><span className="badge"
                                style={{
                                  backgroundColor:
                                    {
                                      H330: '#b8cce4',
                                      H310: '#fcd5b4',
                                      H334: '#b7dee8',
                                      H350: '#c4bd97',
                                      H360: '#ccc0da',
                                    }[hCode.code.toUpperCase().match(/H330|H310|H334|H350|H360/)] || 'transparent',
                                  color: 'black',
                                }} // Set background color based on H-Code
                              >{hCode.code}</span></h5>
                            ))}
                          </TableCell>
                          <TableCell>
                            {row.hCodes.map((hCode, idx) => (
                              <div style={{ wordWrap: "break-word", whiteSpace: "normal" }} key={idx}>{hCode.description}</div> //wrap text here, only applied to description col for now
                            ))}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer> : <AutoLookup onSearch={handleManualSearch} />}
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4} className='auto-lookup-column'>
            <Card variant='lookup'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                  marginTop: '-26px'
                }}>
                <Button
                  variant='contained'
                  color={showAddMaterial ? 'primary' : 'default'}
                  onClick={() => setShowAddMaterial(true)}
                  style={{ marginRight: '25px' }}>
                  Add Material
                </Button>
                <Button
                  variant='contained'
                  color={!showAddMaterial ? 'primary' : 'default'}
                  onClick={() => setShowAddMaterial(false)}>
                  Upload File
                </Button>
              </div>
              {showAddMaterial ? <AddMaterial /> : <FileUpload /> }
            </Card>
            <Card variant='lookup'>
              <DynamicInputField onSearch={handleManualSearch} />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <div className='logout-container'>
        <button
          type='button'
          className='logout-button'
          onClick={handleLogoutClick}>
          Log Out
        </button>
      </div>
    </>
  )
}

export default LookupPage
 