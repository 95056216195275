// RegisterValidation.js

const validate = (formData) => {
  let errors = {}

  const nameRegex = /^[a-zA-Z]*$/ // Only alphabet characters
  const usernameRegex = /^[a-zA-Z0-9_/]*$/ // Only numbers, letters, underscores, and slashes

  if (!formData.firstname.trim()) {
    errors.firstname = 'First name is required'
  } else if (!nameRegex.test(formData.firstname)) {
    errors.firstname = 'First name should contain only alphabet characters'
  }

  if (!formData.lastname.trim()) {
    errors.lastname = 'Last name is required'
  } else if (!nameRegex.test(formData.lastname)) {
    errors.lastname = 'Last name should contain only alphabet characters'
  }

  if (!formData.username.trim()) {
    errors.username = 'Username is required'
  } else if (!usernameRegex.test(formData.username)) {
    errors.username =
      'Username should contain only numbers, letters, underscores, or slashes'
  }

  if (!formData.confirm.trim()) {
    errors.confirm = 'Confirm username is required'
  } else if (formData.confirm.trim() !== formData.username.trim()) {
    errors.confirm = 'Usernames do not match'
  }

  return errors
}

export default validate
