import React, { useRef, useState, useCallback, useEffect } from 'react'
import Swal from 'sweetalert2'
import Webcam from 'react-webcam'
import withReactContent from 'sweetalert2-react-content'
import Switch from 'react-switch'
import Tesseract from 'tesseract.js'
import { BeatLoader } from 'react-spinners'

const autoBaseURL = 'https://api.tesseractdevtool.com/automatic'

function boolCheckSum(casString) {
  const len = casString.length
  const rightIndex = len - 1
  const checkDigit = parseInt(casString[rightIndex], 10)
  let sum = 0

  for (let i = rightIndex - 1; i >= 0; i--) {
    sum += parseInt(casString[i], 10) * (len - i - 1)
  }

  if (sum % 10 === checkDigit) {
    console.log('CheckSum successful')
    return true
  } else {
    console.log('CheckSum failed')
    return false
  }
}

const AutoLookup = ({ onSearch }) => {
  const [cameraOn, setCameraOn] = useState(false)
  const webcamRef = useRef(null)
  const [imgSrc, setImgSrc] = useState(null)
  const [text, setText] = useState(null)
  const [numCameras, setNumCameras] = useState(1)
  const [cameraOrientation, setOrientation] = useState('environment')

  useEffect(() => {
    getNumberOfCameras()
  }, [])

  const getNumberOfCameras = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameraDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        )
        setNumCameras(cameraDevices.length)
      })
      .catch((error) => console.error('Error getting media devices:', error))
  }

  const videoConstraints = {
    facingMode: cameraOrientation
  }

  const retake = () => {
    setImgSrc(null)
    setText(null)
  }

  const handleToggleCamera = () => {
    setCameraOn((prevState) => !prevState)
  }

  const capture = useCallback(() => {
    if (!cameraOn) {
      Swal.fire({
        icon: 'warning',
        title: 'Camera Off',
        text: 'Please turn on the camera to take a photo.'
      })
      return
    }

    const imageSrc = webcamRef.current.getScreenshot()
    setImgSrc(imageSrc)

    Tesseract.recognize(imageSrc, 'eng', {
      logger: (m) => console.log(m)
    }).then(({ data: { text } }) => {
      setText(text)
      console.log('Results:')
      console.log(text)
      const strCAS = text.match(/\b\d{2,7}-\d{2}-\d{1,5}\b/g)
      if (strCAS) {
        withReactContent(Swal)
          .fire({
            icon: 'info',
            html: `<p>Is this the correct CAS? ${strCAS}.</p>`,
            showCancelButton: true,
            confirmButtonColor: '#6ec007',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
          })
          .then((result) => {
            if (result.isConfirmed) {
              handleAutomaticSearch(strCAS)
            }
            retake()
          })
      } else {
        withReactContent(Swal).fire({
          icon: 'error',
          title: 'Error!',
          html: `<p>No CAS number identified.</p>`
        })
        retake()
      }
    })
  }, [cameraOn])

  const handleAutomaticSearch = async (cas) => {
    try {
      console.log(cas)
      const removeHyphen = cas[0].replace(/-/g, '')
      console.log(removeHyphen)
      if (boolCheckSum(removeHyphen)) {
        const response = await fetch(`${autoBaseURL}?cas=${removeHyphen}`)
        const data = await response.json()
        console.log(data)
        if (response.ok) {
          const materials = data.material.map((material) => ({
            cas: material.Cas,
            materialName: material.name,
            hCodes: material.hCodes.map((hCode) => ({
              code: hCode.code,
              description: hCode.description
            }))
          }))
          withReactContent(Swal).fire({
            icon: 'info',
            title: materials[0].materialName,
            html: `<p>Please navigate to the table for detailed information.</p>`
          })
          onSearch(materials) // Pass data to parent
        } else {
          withReactContent(Swal).fire({
            icon: 'error',
            title: 'Error!',
            html: `<p>${data.message}</p>`
          })
        }
      } else {
        withReactContent(Swal).fire({
          icon: 'error',
          title: 'Error!',
          html: `<p>Not a CAS Number</p>`
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <>
      <h2 className='card-title'>Auto Lookup</h2>
      <hr />

      {imgSrc ? (
        <img src={imgSrc} alt='webcam' />
      ) : (
        <div
          className='embed-responsive embed-responsive-4by3'
          style={{ position: 'relative' }}>
          <div
            id='dark-box'
            style={{
              backgroundColor: 'black',
              opacity: 1,
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: cameraOn ? -1 : 1
            }}></div>
          <div id='video-container' style={{ opacity: 1, zIndex: 1 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0
              }}>
              <BeatLoader color='#545453' size={'20px'} />
            </div>
            {cameraOn && (
              <Webcam ref={webcamRef} videoConstraints={videoConstraints} />
            )}
          </div>
        </div>
      )}
      <br />
      <label>
        <div>
          <Switch
            onChange={handleToggleCamera}
            checked={cameraOn}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor='#667EEA'
          />
        </div>
      </label>
      {imgSrc ? (
        <button onClick={retake} className='btn btn-primary btn-block'>
          Retake
        </button>
      ) : (
        <button
          onClick={capture}
          className='btn btn-primary btn-block'
          disabled={!cameraOn} // Disable button if camera is off
        >
          Take Photo
        </button>
      )}

      {numCameras > 1 && (
        <button
          className='btn btn-primary btn-block'
          onClick={() =>
            setOrientation(
              cameraOrientation === 'environment' ? 'user' : 'environment'
            )
          }>
          {cameraOrientation === 'environment' ? 'Front Camera' : 'Back Camera'}
        </button>
      )}
    </>
  )
}

export default AutoLookup
