import { Grid } from '@mui/material'
import Card from '../Card.jsx'
import LoginForm from './LoginForm.jsx'

function LoginPage() {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ minHeight: '100vh' }}
      className='login-page'>
      <Grid item xs={10} sm={9} md={7} lg={6} xl={5}>
        <Card variant='login'>
          <h5 className='card-title text-center mb-4 fw-light fs-4'>Sign In</h5>
          <LoginForm />
          <hr className='my-4' />
          <div className='text-center'>
            <p>
              <a href='/register' className='link-primary'>
                Don't have an account? Register here
              </a>
            </p>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
}

export default LoginPage
