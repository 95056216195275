import React from 'react'
import PropTypes from 'prop-types'
import '../css/card/Card.css'

function Card({ children, className, variant }) {
  return (
    <div
      className={`${className} ${variant === 'lookup' ? 'card-lookup' : 'card-login'}`}>
      <div className={`card border-0 rounded-9 my-3`}>
        <div className='card-body p-4 p-sm-5'>{children}</div>
      </div>
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['lookup', 'login'])
}

export default Card
