import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import validate from './LoginValidation'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function LoginForm() {
  const navigate = useNavigate();
  const baseURL = 'https://api.tesseractdevtool.com/login'; // ALB DNS

  const [formData, setFormData] = useState({
    username: ''
  })
  const [errors, setErrors] = useState({}) // State to hold validation errors

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    // Clear errors for this field when new input is provided
    setErrors({ ...errors, [name]: '' })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validate(formData)
    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the errors state and prevent form submission
      setErrors(validationErrors)
    } else {
      // If there are no validation errors, proceed with the login logic
      setErrors({})
      try {
        const response = await fetch(`${baseURL}?username=${formData.username}`)
        const data = await response.json()
        if (response.ok) {
          // Login successful, navigate to dashboard
          withReactContent(Swal).fire({
            icon: 'success',
            title: data.message,
            html: `<p>Welcome, ${data.user.firstname} ${data.user.lastname}</p>`
          })
          //store the returned token in local storage
          localStorage.setItem('token', data.token)
          navigate('/lookup')
        } else {
          // Login failed, display error message
          withReactContent(Swal).fire({
            icon: 'error',
            title: data.message
          })
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className='login-form'>
      <div
        className={`form-floating mb-3 ${errors.username ? 'has-danger' : ''}`}
        style={{ position: 'relative' }}>
        <input
          type='text'
          className={`form-control ${errors.username ? 'is-invalid' : ''}`}
          id='username'
          placeholder='Username'
          name='username'
          value={formData.username}
          onChange={handleInputChange}
          autoComplete='off'
          required
          style={{
            borderColor: errors.username ? '#dc3545' : '#ced4da', // Change border color if there are errors
            borderWidth: '1px', // Set border width
            borderStyle: 'solid' // Ensure the border style is solid
          }}
        />
        <label htmlFor='username'>Username</label>
        {errors.username && (
          <div className='invalid-feedback'>{errors.username}</div>
        )}
      </div>
      <div className='d-grid mb-3'>
        <button className='btn btn-primary btn-login fw-bold' type='submit'>
          Sign In
        </button>
      </div>
    </form>
  )
}

export default LoginForm
