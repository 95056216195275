import React, { useCallback, useState } from 'react'
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'

function FileUpload() {
  const [dragOver, setDragOver] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setUploadError] = useState(null)
  const [filePreviews, setFilePreviews] = useState([])
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState([]) // State to track uploaded files

  const handleDragOver = useCallback((event) => {
    event.preventDefault()
    setDragOver(true)
  }, [])

  const handleDragLeave = useCallback((event) => {
    event.preventDefault()
    setDragOver(false)
  }, [])

  const handleDrop = useCallback((event) => {
    event.preventDefault()
    setDragOver(false)
    if (event.dataTransfer.files) {
      handleFiles(event.dataTransfer.files)
    }
  }, [])

  const handleChange = useCallback((event) => {
    if (event.target.files) {
      handleFiles(event.target.files)
    }
  }, [])

  const handleFiles = (files) => {
    const fileArray = Array.from(files)

    // Reset states
    setUploadError(null)
    setFilePreviews([])
    setUploadProgress(0)

    // Check if more than one file is selected or dropped
    if (fileArray.length > 1) {
      setUploadError('Please upload only one .xlsx file.')
      setSnackbarOpen(true)
      return
    }

    const validFiles = fileArray.filter(
      (file) =>
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )

    if (validFiles.length === 1) {
      const file = validFiles[0]
      setFileToUpload(file)
      setFilePreviews([
        {
          name: file.name,
          type: file.type
        }
      ])
      setConfirmationDialogOpen(true)
    } else if (fileArray.length === 1) {
      // File is not an .xlsx file
      setUploadError('Please upload a valid .xlsx file.')
      setSnackbarOpen(true)
    } else {
      // Handle case with no files
      setUploadError('No files were uploaded.')
      setSnackbarOpen(true)
    }
  }

  const handleConfirmUpload = () => {
    if (fileToUpload) {
      uploadFile(fileToUpload)
      setConfirmationDialogOpen(false)
    }
  }

  const handleCancelUpload = () => {
    setConfirmationDialogOpen(false)
    setFileToUpload(null)
    setFilePreviews([]) // Clear file previews if upload is canceled
  }

  const uploadFile = (file) => {
    const xhr = new XMLHttpRequest()
    const formData = new FormData()
    formData.append('file', file)

    xhr.open('POST', 'https://api.tesseractdevtool.com/upload', true)

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = (event.loaded / event.total) * 100
        setUploadProgress(percentCompleted)
      }
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        setUploadProgress(100)
        setUploadError(null)
        setUploadedFiles([...uploadedFiles, file]) // Add file to uploadedFiles
        setSnackbarOpen(true)
      } else {
        setUploadError(`Upload failed with status ${xhr.status}`)
        setSnackbarOpen(true)
      }
    }

    xhr.onerror = () => {
      setUploadError('Network error occurred')
      setSnackbarOpen(true)
    }

    xhr.send(formData)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Paper
      variant='outlined'
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? '2.5px dashed #1976d2' : '2.5px dashed #ddd',
        padding: 20,
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#f5f5f5',
        background: dragOver ? '#e3f2fd' : '#fafafa',
        transition: 'background 0.3s, border 0.3s',
        position: 'relative',
        borderRadius: 8,
        boxShadow: dragOver
          ? '0px 0px 10px rgba(0, 0, 0, 0.1)'
          : '0px 4px 8px rgba(0, 0, 0, 0.1)'
      }}>
      <input
        accept='.xlsx'
        style={{ display: 'none' }}
        id='raised-button-file'
        type='file'
        onChange={handleChange}
      />
      <label
        htmlFor='raised-button-file'
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative'
        }}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          style={{ width: '100%', minHeight: '303px', marginTop: '4px' }}>
          <IconButton
            color='primary'
            aria-label='upload spreadsheet'
            component='span'
            style={{ marginBottom: 10 }}>
            <AttachFileIcon />
          </IconButton>
          <Typography variant='body1'>
            Drag and drop .xlsx files here or click to select files
          </Typography>
          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box
              sx={{
                width: '100%',
                height: 4,
                backgroundColor: '#FF5733',
                borderRadius: 2,
                position: 'absolute',
                bottom: 0,
                left: 0,
                transform: `scaleX(${uploadProgress / 100})`,
                transformOrigin: 'left',
                transition: 'transform 0.3s'
              }}
            />
          )}
          {uploadProgress === 100 && (
            <Typography
              variant='body2'
              color='textSecondary'
              style={{ marginTop: 10, color: '#1976d2' }}>
              Upload Complete
            </Typography>
          )}
          {uploadedFiles.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto',
                marginTop: 2
              }}>
              {uploadedFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Typography variant='body2'>{file.name}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </label>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={uploadError ? 'error' : 'success'}>
          {uploadError || 'Upload successful!'}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationDialogOpen} onClose={handleCancelUpload}>
        <DialogTitle>Confirm Upload</DialogTitle>
        <DialogContent>
          <Typography variant='body1'>
            Are you sure you want to upload the file: {fileToUpload?.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpload} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default FileUpload
