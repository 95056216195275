export default function validate(values) {
  let errors = {}
  if (!values.username) {
    errors.username = 'Username is required'
  } else if (!/^[a-zA-Z0-9_-]+$/.test(values.username)) {
    errors.username =
      'Username should contain only alphanumeric characters, underscores, or dashes'
  }
  return errors
}
