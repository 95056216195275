import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const RequireAuth = ({ isAuthenticated }) => {
  const [authState, setAuthState] = useState(null); // null: loading, true: authenticated, false: not authenticated

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const result = await isAuthenticated(); // Await the function call
        setAuthState(result); // Set the result (true or false)
      } catch (error) {
        console.error('Error checking authentication:', error);
        setAuthState(false); // Assume not authenticated in case of error
      }
    };

    checkAuth(); // Call the authentication check on component mount
  }, [isAuthenticated]);

  // Show a loading state while authentication is being resolved
  if (authState === null) {
    return <div>Loading...</div>;
  }

  // Redirect to login if not authenticated
  if (!authState) {
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};

export default RequireAuth;
