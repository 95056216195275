import React, { useState } from 'react'
import Swal from 'sweetalert2'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'

const manualBaseURL = 'https://api.tesseractdevtool.com/manual'

const DynamicInputField = ({ onSearch }) => {
  const [inputs, setInputs] = useState([''])
  const [errors, setErrors] = useState([])

  const handleChange = (index, event) => {
    const values = [...inputs]
    values[index] = event.target.value
    setInputs(values)
    setErrors(errors.map((error, i) => (i === index ? '' : error)))
  }

  const handleAddInput = () => {
    setInputs([...inputs, ''])
    setErrors([...errors, ''])
  }

  const handleRemoveInput = (index) => {
    const values = [...inputs]
    values.splice(index, 1)
    setInputs(values)
    setErrors(errors.filter((_, i) => i !== index))
  }

  const handleManualSearch = async (e) => {
    e.preventDefault()

    const items = inputs.filter((item) => item.trim() !== '')

    // Check for empty or incorrect CAS numbers
    const newErrors = inputs.map((input) =>
      input.trim() === '' || !/^[0-9]{1,9}-[0-9]{2}-[0-9]{1}$/.test(input)
        ? 'Invalid CAS'
        : ''
    )

    if (newErrors.some((error) => error)) {
      setErrors(newErrors)
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please check the CAS numbers.'
      })
      return
    }

    if (items.length === 0) {
      // Inform parent to clear the table
      onSearch([])
      return
    }

    try {
      const formattedCAS = items
        .map((cas) => `'${cas.replace(/-/g, '')}'`)
        .join(',')

      const response = await fetch(`${manualBaseURL}?cas=${formattedCAS}`)
      const data = await response.json()

      if (response.ok) {
        const materials = data.material.map((material) => ({
          cas: material.Cas,
          materialName: material.name,
          hCodes: material.hCodes.map((hCode) => ({
            code: hCode.code,
            description: hCode.description
          }))
        }))
        onSearch(materials) // Pass data to parent
        setInputs(['']) //Clear the inputs after successful search
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          html: `<p>${data.message}</p>`
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }

  }

  return (
    <form onSubmit={handleManualSearch} style={{ minHeight: '162px' }}>
      <h2>Manual Lookup</h2>
      <hr />
      <div>
        {inputs.map((input, index) => (
          <div
            className='form-floating mb-2'
            key={index}
            style={{ marginBottom: '10px', position: 'relative' }}>
            <input
              type='text'
              value={input}
              onChange={(event) => handleChange(index, event)}
              id={`casInput-${index}`}
              placeholder='Enter CAS number (e.g., 1234-56-7)'
              style={{
                marginRight: '10px',
                border: `2px solid ${errors[index] ? 'red' : '#00000029'}`,
                borderRadius: '4px'
              }}
              className='form-control'
            />
            <label htmlFor={`casInput-${index}`}>CAS Number</label>
            <a
              type='button'
              className=''
              onClick={() => handleRemoveInput(index)}
              style={{
                position: 'absolute',
                top: '0px',
                right: '10px',
                borderRadius: '10%',
                width: 'auto',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <DisabledByDefaultRoundedIcon color='error' fontSize='medium' />
            </a>
          </div>
        ))}
        <div className='d-flex'>
          <button
            type='button'
            className='btn btn-primary item mr-2'
            onClick={handleAddInput}>
            Add CAS
          </button>
          <button type='submit' className='btn btn-primary btn-block item'>
            Search
          </button>
        </div>
      </div>
    </form>
  )
}

export default DynamicInputField
