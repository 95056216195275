import React, { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const addMaterialBaseURL = 'https://api.tesseractdevtool.com/addmaterial'; // ALB DNS

function AddMaterial() {
  const [formData, setFormData] = useState({
    inputCas: '',
    inputName: '',
    hazardCode: { code: '', description: '' } // Initialize with one hazard code-description pair
  })

  const [errors, setErrors] = useState({
    inputCas: false,
    inputName: false,
    hazardCode: false
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      hazardCode: { ...formData.hazardCode, [name]: value }
    })
    setErrors({ ...errors, hazardCode: false })
  }

  const handleInputChangeBasic = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: false })
  }

  const handleAddMaterial = async (e) => {
    e.preventDefault()

    const newErrors = {
      inputCas: !formData.inputCas,
      inputName: !formData.inputName,
      hazardCode: !formData.hazardCode.code || !formData.hazardCode.description
    }

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors)
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Please fill out all required fields.'
      })
      return
    }

    try {
      const removeHyphen = formData.inputCas.replace(/-/g, '')
      const response = await fetch(addMaterialBaseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          cas: removeHyphen,
          name: formData.inputName,
          hazardCodes: [formData.hazardCode] // Send as array with one element
        })
      })
      const data = await response.json()
      if (response.ok) {
        withReactContent(Swal).fire({
          icon: 'success',
          title: data.message,
          html: `<p>${data.material.Cas} ${data.material.name}</p><ul>${data.material.hazardCodes.map((h) => `<li>${h.code}: ${h.description}</li>`).join('')}</ul>`
        })
        setFormData({
          inputCas: '',
          inputName: '',
          hazardCode: { code: '', description: '' }
        })
        setErrors({
          inputCas: false,
          inputName: false,
          hazardCode: false
        })
      } else {
        withReactContent(Swal).fire({
          icon: 'error',
          title: 'Error!',
          html: `<p>${data.message}</p>`
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <form onSubmit={handleAddMaterial}>
      <h2>Add Material</h2>
      <hr />
      <div className='form-floating mb-2'>
        <input
          style={{
            marginRight: '10px',
            border: `2px solid ${errors.inputCas ? 'red' : '#00000029'}`,
            borderRadius: '4px'
          }}
          id='addCas'
          className='form-control rounded'
          type='text'
          placeholder='CAS number'
          name='inputCas'
          value={formData.inputCas}
          onChange={handleInputChangeBasic}
        />
        <label htmlFor='addCas'>CAS Number</label>
      </div>
      <div className='form-floating mb-2'>
        <input
          style={{
            marginRight: '10px',
            border: `2px solid ${errors.inputName ? 'red' : '#00000029'}`,
            borderRadius: '4px'
          }}
          id='addName'
          className='form-control rounded'
          type='text'
          placeholder='Material Name'
          name='inputName'
          value={formData.inputName}
          onChange={handleInputChangeBasic}
        />
        <label htmlFor='addName'>Material Name</label>
      </div>
      <div className='form-floating mb-2'>
        <input
          style={{
            marginRight: '10px',
            border: `2px solid ${errors.hazardCode ? 'red' : '#00000029'}`,
            borderRadius: '4px'
          }}
          id='hazardCode'
          className='form-control rounded'
          type='text'
          placeholder='Hazard Code'
          name='code'
          value={formData.hazardCode.code}
          onChange={handleInputChange}
        />
        <label htmlFor='hazardCode'>Hazard Code</label>
      </div>
      <div className='form-floating mb-2'>
        <input
          style={{
            marginRight: '10px',
            border: `2px solid ${errors.hazardCode ? 'red' : '#00000029'}`,
            borderRadius: '4px'
          }}
          id='hazardDescription'
          className='form-control rounded'
          type='text'
          placeholder='Description'
          name='description'
          value={formData.hazardCode.description}
          onChange={handleInputChange}
        />
        <label htmlFor='hazardDescription'>Description</label>
      </div>
      <button type='submit' className='btn btn-primary btn-block item'>
        Add Material
      </button>
    </form>
  )
}

export default AddMaterial

